<template>
  <div class="grow center wrap">
    <form @submit.prevent="post" class="container center d-col">
      <s-logo />
      <h1 class="d-flex green--text font-48 text-center">
        <div class="bold">{{page}} Forgot Password</div>
      </h1>
      <div class="opacity-60 font-14 mb-20 text-center">
        {{ $t("forgotPassword.title") }}
      </div>
      <div
        class="missingText red--text mb-10"
        :class="{ 'green--text': res.status == 'ok' }"
      >
        {{ msg }}
      </div>
      <s-text-field v-model="email" block details label="Email"></s-text-field>
      <s-btn block class="green" height="40">
        {{ $t("forgotPassword.submit") }}</s-btn
      >
      <router-link class="green--text mt-15 font-14 d-flex center" to="login">
        <img class="green_svg mr-10" src="img/icons/arrow-up.svg" alt="" />
        <div>{{ $t("forgotPassword.back") }}</div>
      </router-link>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      msg: "",
      res: "",
      sent: false,
    };
  },
  methods: {
    async post() {
      this.res = "";
      if (this.email.length == 0)
        return (this.msg = this.$t("forgotPassword.enter_email"));
      if (!this.validateEmail())
        return (this.msg = this.$t("forgotPassword.email_valid"));

      if(this.isPartners){
        let res = await this.$store.dispatch("user/forgotResellerPassword", this.email);
      }else{
        let res = await this.$store.dispatch("user/forgotPassword", this.email);
      }

      this.res = res;
      let errMsg = this.$t("forgotPassword.email_sent");
      if (res.status == "ok") return (this.msg = errMsg);
      if (res.status == "error") return (this.msg = res.errorCode);
    },
    validateEmail() {
      var regex =
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(this.email);
    },
  },
  computed: {
    isPartners() {
      return this.$route.path.includes("partners");
    },
    page(){
      return this.isPartners ? 'Partner' : 'Customer'
    }
  },
};
</script>

<style scoped lang="less">
.container {
  width: 100%;
  max-width: 400px;
  margin: 10px;
}
</style>
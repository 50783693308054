<template>
<div class="h100 vh100 d-flex d-col">
   <TopToolbar />
   <LostPassword />
</div>
</template>

<script>
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import LostPassword from "@/components/Auth/ForgotPassword/ForgotPassword.vue";


export default {
  name: "Register",
  components: {
    LostPassword,
    TopToolbar
  },
};
</script>

